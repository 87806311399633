/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { SendParcelWidget, TrackingSearchWidget } from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import * as analytics from '../../utils/analytics';
import { isBrowser } from '../../utils';
import { Button, Link } from '../../components';

export default ({ location, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const title = translate('account.parcelDetails.packagingInstructions.title');
  const isExpress = isBrowser && new URLSearchParams(location.search).get('express') === 'true';
  const isPickup = isBrowser && new URLSearchParams(location.search).get('pickup') === 'true';
  const isReturnShipment = isBrowser && new URLSearchParams(location.search).get('return') === 'true';
  const translationPrefix = isExpress
    ? 'express'
    : `${isReturnShipment ? 'return.' : ''}${isPickup ? 'pickup' : 'noPickup'}`;

  const goBack = useCallback(() => window.history.back(), []);
  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Box>
          <SendParcelWidget sidebar sx={{ mb: 3 }} />
          <TrackingSearchWidget sidebar />
        </Box>
      }
    >
      <div>
        <Box sx={{ mt: 4 }}>
          <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backButton')}
          </Button>
        </Box>

        <Styled.h1>{translate(`account.parcelDetails.packagingInstructions.header`)}</Styled.h1>
        <p>{translate(`account.parcelDetails.packagingInstructions.${translationPrefix}.1`)}</p>
        <p>{translate(`account.parcelDetails.packagingInstructions.${translationPrefix}.2`)}</p>
        <p>{translate(`account.parcelDetails.packagingInstructions.${translationPrefix}.3`)}</p>
        {!isPickup && (
          <Button as={Link} to={'/service-points'} variant="plain" sx={{ color: 'primary' }}>
            {translate('account.parcelDetails.packagingInstructions.findServicePoints')}
          </Button>
        )}
      </div>
    </AccountLayout>
  );
};
